import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingSign = () => {
  return (
    <div className="fixed z-50 flex items-center justify-center w-full h-full bg-blue-900">
      <FontAwesomeIcon icon={faSpinner} size="5x" color='white' spin />
    </div>
  );
};

export default LoadingSign;
