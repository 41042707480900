

import  {Hero}  from "./components/Hero";
//import HeroSection from "./components/HeroSection";
import Navbar from "./components/Navbar";


function App() {
  return (
    <>
      <Navbar />
      <Hero />
    
      
      
     
     
    </>
  );
}

export default App;
