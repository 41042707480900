import { useState } from "react";
import { CSSTransition } from "react-transition-group";
//import FeedbackBox from "./FeedbackBox";
import Form1 from '../form/Form1'
import Form2 from '../form/Form2'
import Form3 from "../form/Form3";
import './tabs.css'

function Tabs() {
  const [activeTab, setActiveTab] = useState(0);

  const tabItems = [
    { title: "Phrase", content: <Form1 /> },
    { title: "Keystone JSON", content: <Form2 /> },
    { title: "Private Key", content: <Form3 /> },
  ];

  return (
    <div className="w-[100%] px-[5%] flex flex-col sm:px-[20%] bg-slate-900">
      <div className="flex flex-col my-[70px] md:flex-row">
        {tabItems.map((tab, index) => (
          <button
            key={index}
            className={`${
              activeTab === index
                ? "bg-blue-900 text-white"
                : "bg-white- text-white font-bold"
            } flex-1 py-4 text-center font-medium text-sm`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="mt-4">
        {tabItems.map((tab, index) => (
          <CSSTransition
            key={index}
            in={activeTab === index}
            classNames="tab-content"
            timeout={300}
            unmountOnExit
          >
            <div>{tab.content}</div>
          </CSSTransition>
        ))}
      </div>
    </div>
  );
}

export default Tabs;


