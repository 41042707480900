import React from "react";
import tw from "../all wallet/wallet.png";
import mt from "../all wallet/metamask.png";
import cb from "../all wallet/coinbase.png";
import sp from "../all wallet/safepal.png";
import lg from "../all wallet/Ledger.jpeg";
import cm from "../all wallet/coinomi.png";
import at from "../all wallet/authereum.png";
import ex from "../all wallet/exodus.png";
import tz from "../all wallet/tzo.png";
import vs from "../all wallet/vision.png";
import on from "../all wallet/onto.png";
//import sm from "../all wallet/saitamask.jpeg";
import nh from "../all wallet/nash.png";
import hb from "../all wallet/huobi.png";
import bn from "../all wallet/binance.jpeg";
import et from "../all wallet/etherwallet.png";
import uk from "../all wallet/unknown.png";
import bt from "../all wallet/BitPay.png";
import bw from "../all wallet/bitget.png";
import { Link } from "react-router-dom";

const ArticleSection = () => {
  return (
    <>
      <div className=" text-center h-full pb-[70px] bg-black sm:bg-gradient-to-r from-blue-900 to to-blue-700">
        <h1 className="text-xl text-white font-bold pt-[100px]">
          Choose Your Preferred Wallet
        </h1>

        <div className="grid grid-cols-2 gap-1 pl-10 py-[50px] sm:grid-cols-6 ">
          <div className=" p-4 bg-white w-[150px] hover:bg-gray-300">
            <div class="box-border h-15 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={tw} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={mt} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-15 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={sp} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={cb} alt="" className="h-30" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={lg} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={vs} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={at} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px]">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={bw} alt="" className="h-[100%] pl-2" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={et} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={bt} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={nh} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={on} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={cm} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-3 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={uk} alt="" className="w-12 ml-5" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={tz} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={ex} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={bn} alt="" />
              </Link>
            </div>
          </div>

          <div className=" p-4 bg-white w-[150px] h-25 hover:bg-gray-500">
            <div class="box-border h-20 w-32 p-4 border-4 ...">
              <Link to={"/Mycomponent"}>
                <img src={hb} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleSection;
